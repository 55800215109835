@import '~antd/dist/antd.less';

@font-face {
  font-family: 'Anuphan';
  src: local('Anuphan'), url(./fonts/Anuphan-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Anuphan';
  src: local('Anuphan'), url(./fonts/Anuphan-Bold.otf) format('opentype');
  font-weight: 700;
}
@font-face {
  font-family: 'Anuphan';
  src: local('Anuphan'), url(./fonts/Anuphan-SemiBold.otf) format('opentype');
  font-weight: 600;
}

.App {
  text-align: center;
}

body {
  font-family: Anuphan, Arial, sans-serif;
}
.ant-btn {
  box-shadow: 3px 3px 6px #00000029;
  border-radius: 10px;
  padding: 4px 30px;
}
.ant-btn-primary {
  background: #525ca3;
  border-color: #525ca3;
}
.ant-card-bordered .ant-card-cover {
  background: #525ca3;
}
.ant-input {
  border: 1px solid #707070;
  border-radius: 10px;
}
.gray {
  color: #b0afaf;
}

@primary-color: #876CA2;